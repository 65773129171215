@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-Light.otf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-LightItalic.otf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-Book.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-BookItalic.otf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-Medium.otf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-MediumItalic.otf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-Bold.otf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-BoldItalic.otf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-Black.otf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../assets/fonts/circular-std/CircularStd-BlackItalic.otf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
/* 
@font-face {
  font-family: 'Utile';
  src: url('../assets/fonts/utile/Utile-Light.otf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Utile';
  src: url('../assets/fonts/utile/Utile-LightItalic.otf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Utile';
  src: url('../assets/fonts/utile/Utile-Book.otf') format('truetype');
  font-weight: 350;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Utile';
  src: url('../assets/fonts/utile/Utile-BookItalic.otf') format('truetype');
  font-weight: 350;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Utile';
  src: url('../assets/fonts/utile/Utile-Regular.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Utile';
  src: url('../assets/fonts/utile/Utile-Italic.otf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Utile';
  src: url('../assets/fonts/utile/Utile-Medium.otf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Utile';
  src: url('../assets/fonts/utile/Utile-MediumItalic.otf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Utile';
  src: url('../assets/fonts/utile/Utile-Semibold.otf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Utile';
  src: url('../assets/fonts/utile/Utile-SemiboldItalic.otf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Utile';
  src: url('../assets/fonts/utile/Utile-Bold.otf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Utile';
  src: url('../assets/fonts/utile/Utile-BoldItalic.otf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Utile';
  src: url('../assets/fonts/utile/Utile-Black.otf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Utile';
  src: url('../assets/fonts/utile/Utile-BlackItalic.otf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
} */

body {
  background: #fff;
  /* color: #fff; */
  font-family: 'Utile', 'Open Sans', sans-serif;
  font-weight: normal;
  font-size: 1rem;
}

.green-background {
  background-image: url('../assets/images/green-background.svg');
  background-size: cover;
}

.blue-background {
  background-image: url('../assets/images/blue-background.svg');
  background-size: cover;
}

.text-blue {
  color: #034264 !important;
}

.bg-blue {
  background-color: #034264 !important;
}

.text-green {
  color: #20bf55 !important;
}
.bg-green {
  background-color: #20bf55 !important;
}

.bg-green-light {
  background-color: #f5fef8 !important;
}

.custom-table {
  border-collapse: separate;
  border-spacing: 0 15px;
}

.custom-tr {
  background: #fff;
  padding: 10px 0px;
}

.logo-img {
  height: 23px;
  margin: 10px 0px 0px 10px;
}

.request-btn {
  background-color: #1489c8;
  border-radius: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #ffffff;
}

.request-btn-white {
  background-color: #1489c8;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #ffffff;
}

.nav-link-custom.active {
  background-color: #091b00;
  color: white;
}

.nav-link-custom:hover {
  background-color: #091b00;
  color: white;
}

.nav-link-custom {
  color: white;
  border-radius: 10px;
}

.nav-item {
  padding: 7px;
  text-align: center;
}

/*
 * Sidebar
 */

.sideshow {
  display: block;
  opacity: 1;
  transition: all 1.5s;
}

.sidehide {
  display: none;
  /* opacity: 0; */
  transition: all 1.5s;
}

.right-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  background-color: transparent;
  margin-bottom: 20px;
  word-wrap: break-word;
  transition: all 0.5s;
}

.right-sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  padding: 1rem 2rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  background-color: #fff;
  color: #000;
  box-shadow: 0 8px 12px 0 rgba(19, 19, 19, 0.2);
  transition: all 0.5s;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  /* margin-top: 10px; */
  background-color: transparent;
  margin-bottom: 20px;
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  background-color: #20bf55;
  color: white;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link-custom {
  font-weight: 300;
  color: #fff;
  text-align: left;
}

.sidebar .nav-link-custom.active {
  color: #ffffff;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

/* media screen and  767px*/
@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }

  .sidebar-sticky {
    margin-top: -80px !important;

    width: 40%;
  }

  .right-sidebar-sticky {
    margin-top: 40px !important;
  }

  .request-spinner {
    right: 5%;
  }
}

@media (max-width: 575.98px) {
  .modal-window > div {
    width: 360px;
  }

  .sidebar-sticky {
    /* margin-top: 40px !important; */
    width: 55%;
  }
}

/*
 * Navbar
 */

.navbar .navbar-toggler {
  top: 0.25rem;
  right: 1rem;
}

.tracking-card {
  border-radius: 15px;
  background-color: #fff;
  border: 2px solid #d3d2d2;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  padding: 24px 16px;
}

.card {
  margin: 10px 0px;
  border-color: transparent;
  border-radius: 10px;
}

.card.search-card {
  margin-bottom: 30px;
  background-color: transparent;
  min-height: 150px;
  border: 2px solid #d3d2d2;
  margin-left: 10px;
}
.card-title.search-title {
  font-weight: 400;
  font-size: 1.1rem;
  margin-bottom: 10px;
}
.card-text.search-text {
  font-size: 0.9rem;
}

.table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: #e5fae8;
}

.bmi-calc {
  margin: 10px 30px;
}

.card-shadow:hover {
  box-shadow: 0 3px 9px 3px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.card-shadow {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.card-shadow-success:hover {
  box-shadow: 0 3px 9px 3px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  background-color: #28a745 !important;
}
.card-shadow-selected {
  box-shadow: 0px 0px 5px 0px #28a745;
  cursor: pointer;
}
/*
.collapse {
  display: block;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}
.collapse.show {
  max-height: 99em;
  transition: max-height .5s ease-in-out;
}*/

/**/
.nav-dropdown {
  position: absolute !important;
  top: 70%;
  right: -20%;
  left: unset;
}

.nav-dropdown-toggle {
  display: inline;
}

.nav-dropdown-toggle:hover {
  background-color: #f8f9fa !important;
}

.btn-spinner {
  margin-top: 5px;
  float: right;
}

.btn-close {
  float: right;
  margin-top: -10px;
}

/*spinner*/
.request-spinner {
  z-index: 1000;
  position: fixed;
  right: 3%;
  margin-top: 5px;
}

.fade-wrapper {
  display: none;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
}

/*Css only modal: https://codepen.io/timothylong/pen/HhAer*/
.modal-window {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.25);
  top: 10%; /*0*/
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  /*visibility: visible;*/
  display: none;
  opacity: 1;
  pointer-events: auto;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.modal-window:target {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.modal-window > div {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 2em;
  background: #ffffff;
  border: 1px solid #1489c8;
}

.modal-window header {
  font-weight: bold;
}

/*.modal-window h1 {
		font-size: 150%;
		margin: 0 0 15px;
	}*/

.modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
  cursor: pointer;
}

.modal-close:hover {
  color: black;
}

/*Modal ends*/

/* font sizes */

.fs-5 {
  font-size: 1.5rem;
}

.fs-6 {
  font-size: 1rem;
}

.fs-7 {
  font-size: 0.9rem;
}

.fs-8 {
  font-size: 0.75rem;
}

.fs-9 {
  font-size: 0.6rem;
}

.fw-bold {
  font-weight: bold;
}

.fw-normal {
  font-weight: normal;
}

.fw-light {
  font-weight: 300;
}

.bread-space {
  margin: 0rem 0.6rem;
}

.chat-later {
  margin-top: 10px;
  font-size: 0.85rem;
}

.pointer {
  cursor: pointer;
}

/* refresh toast notification */
.refresh-toast {
  float: right;
  padding: 70px;
  position: fixed;
  left: 50%;
  color: #1489c8;
  font-size: 1.2rem;
  box-shadow: 0 1px 15px 5px rgba(1, 10, 1, 0.2);
  top: 50%;
  background-color: white;
  font-weight: 600;
  cursor: pointer;
  transform: translate(-50%, -50%);
}

.exclamation-desc {
  margin-top: '1rem';
}

.exclamation-icon {
  width: '4rem';
}

.arrow-right-icon {
  margin: '1.1rem 0rem';
  width: '1rem';
}

/* overrides */

.form-control:focus {
  border-color: #20bf55;
  box-shadow: unset;
}

/* media screen and  767px*/
@media (max-width: 767.98px) {
  .sidebar {
    top: 2rem;
  }

  .detail-item {
    padding: 20px 0px;
    font-size: 1rem;
    font-weight: 300;
    text-align: center;
  }

  .sidebar-sticky {
    margin-top: -20px !important;
  }

  .request-spinner {
    right: 5%;
  }
}

@media (max-width: 575.98px) {
  .modal-window > div {
    width: 360px;
  }
}
.group-icon:hover {
  color: #1489c8;
}

.group-icon {
  font-size: 20px;
  cursor: pointer;
}

.align-items-middle {
  display: flex;
  align-items: center;
}

.align-grid-items-middle {
  display: grid;
  align-items: center;
}

.search-icon {
  color: #59b32d;
  position: absolute;
  margin-top: 5px;
  left: 25px;
  cursor: pointer;
  padding: 2px 2px;
}

/*Patient search bar*/

.search-result {
  padding: 4px;
  background: white;
  list-style: none;
}

.search-result:hover {
  color: #212529;
  /* background-color: rgba(0, 0, 0, 0.075); */
  cursor: pointer;
  cursor: pointer;
  background-color: #ddeef7;
}
.search-result-panel {
  position: absolute;
  min-width: 95%;
  display: block;
  height: auto;
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1;
  border: 1px solid #0094ff;
  box-shadow: 0 0 10px #333333;
  background-color: white;
}

.track-line {
  width: 5px;
  background: greenyellow;
  height: 100%;
  float: right;
  margin-right: 10px;
}

/* Claim Utilization */
.section-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.data-card {
  flex: 1 0 18%;
  margin: 0.5%;
}

.icon-box {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* indicator css */
.new-feature-indicator {
  display: inline-block;
  font-size: 1.5em; /* Adjust size as needed */
  line-height: 0;
  vertical-align: middle;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
